<style lang="scss" scoped>
	@import "./new.page";
</style>
<template>
  	<div>
		<div class="new-quotation-request">
			<div class="body">
				<div class="mt-5 container-fluid">
					<div class="row contents">
						<div class="col-6">
							<h4 class="header-title"><i class="fa fa-cart-plus" aria-hidden="true"></i>Nova Cotação / Requisição</h4>
							<p class="header-subtitle">Escolha os itens e envie para seus fornecedores ou para sua Central de Compras</p>
						</div>
					</div>
					<div class="row contents form-content p-2 mt-3 rounded">
						<div class="col-8 pl-5">
							<form name="filter-form">
								<div class="form-row">
									<div class="form-group col-md-6">
										<label class="label-control" for="inputEmail4">*Empresa <span style="font-size: 0.8rem;">(obrigatorio)</span>:</label>
										<cc-select-v2 :tracker="'loj_id'" :text="'loj_nomeFantasia'" :options="stores" v-model="quotation.store" @onselect="check_type"></cc-select-v2>
									</div>
									<div class="form-group col-md-6">
										<label class="label-control" for="inputPassword4">*Tipo <span style="font-size: 0.8rem;">(obrigatorio)</span>:</label>
										<cc-select-v2 :tracker="'id'" :text="'name'" :options="types" v-model="quotation.type" @onselect="check_type"></cc-select-v2>
									</div>
								</div>
							</form>
						</div>
						<div class="col-4 d-flex buttons justify-content-center align-middle mt-4">
							<cc-button :classes="'danger-outline'" :content="'Remover Digitados'" :icon="'fas fa-trash'" @click="clear()" />
							<cc-button :classes="'success'" :content="'Enviar'" :icon="'fa fa-shopping-cart'" :disabled="!quotation.store || !quotation.type" @click="open_modal()" />
						</div>
					</div>
					<div class="row contents mt-4" v-show="quotation.store && quotation.type && quotation.type.id">
						<div class="col-lg-12 shadow p-4 mb-5">
							<div class="filters col-12 p-2 pb-3">
								<div class="row">
									<div class="col">
										<label class="label-control"></label>
										<cc-search class="mt-1" v-model="product_name" placeholder="Buscar produto" @keyup="load_products" @search="load_products" />
									</div>
									<div class="col" v-if="is_direct_sale()">
										<label class="label-control">Fornecedor</label>
										<cc-select-v2 :tracker="'for_id'" :text="'for_nome'" :options="providers" v-model="provider"></cc-select-v2>
									</div>
									<div class="col" v-if="!is_direct_sale()">
										<label class="label-control">Categoria</label>
										<cc-select-v2 :tracker="'cat_id'" :text="'cat_descricao'" :options="categories" v-model="quotation.categoria"></cc-select-v2>
									</div>
									<div class="col-2 justify-content-end d-flex pt-2">
										<cc-checkbox class="mt-4" v-model="only_typed_filter" :label="`Apenas Digitados? (${count()})`" />
									</div>
									<div class="col-2 justify-content-end d-flex pt-4" v-if="!is_direct_sale()">
										<cc-button :classes="'success fixed'" :content="'Adicionar Produto'" :icon="'fa fa-plus-circle'" @click="show_add_product_modal = !show_add_product_modal" />
									</div>
								</div>
							</div>
							<cc-loader v-show="is_loading" />
							<no-data-available v-if="products.length == 0 && !is_loading" :text="'Nenhum produto encontrado'" />
							<table v-show="products.length > 0" class="table" :class="{ hidden : is_loading }">
								<thead>
									<tr v-if="is_direct_sale()">
										<th class="text-left pl-2">Quantidade</th>
										<th class="text-left">Produto</th>
										<th class="text-left">Embalagem</th>
										<th class="text-left">Codigo</th>
										<th class="text-left">Preco</th>
										<th class="text-left">Prc.Emb</th>
									</tr>
									<tr  v-if="!is_direct_sale()">
										<th class="text-left pl-2">Quantidade</th>
										<th class="text-left">Produto</th>
										<th class="text-left">Embalagem</th>
										<th class="text-left">Codigo</th>
										<th class="text-left" v-if="is_direct_sale()">Preco</th>
										<th class="text-left" v-if="is_direct_sale()">Preco Emb.</th>
									</tr>
								</thead>
								<tbody>

									<tr v-for="(product, idx) in products" :key="'prod-' + product.pro_id" :class="{ active : product.focused == true}">
										<td class="amount-controls d-flex">
											<input
											min="0"
											type="number"
											:data-idx="idx"
											ref="product-amount"
											class="product-amount mx-2"
											:class="{ active : product.new_amount && product.new_amount > 0}"
											v-model.number="product.new_amount"
											@focus="is_focused(idx, product)"
											@change="() => product.changed = true"
											v-on:keydown="process_event($event, product)"
											:key="'qtd' + product.prod_id" />
											<cc-button @click="showMarcas(product)" :classes="'secondary'" :disabled="product.pro_generico == 0" :content="'Marcas'" />
										</td>
										<td class="product text-left">{{ product.pro_descricao.toUpperCase() }}</td>
										<td class="text-left">
                                            <i class="far fa-edit" @click="open_edit_wrap = true;current_product=product" v-if="product.pro_generico == 0"/>
                                            {{ product.pro_generico ? product.pro_unidade_compra : product.pro_embalagem }}
                                        </td>
										<td class="text-left">{{ product.pro_ean }}</td>
										<td class="text-left" v-if="is_direct_sale()">{{ product.preco_unitario | currency('R$ ', 2) }}</td>
										<td class="text-left" v-if="is_direct_sale()">{{ product.preco_unitario*product.pro_embalagemQtd | currency('R$ ', 2) }}</td>
									</tr>
								</tbody>
							</table>
            			</div>
					</div>
					<div class="footer" :key="'footer'" v-show="quotation.store && quotation.type && quotation.type.id">
						<cc-pagination
						:key="'pag'"
						classes="orange"
						:items_by_page_value="items_by_page_value"
						:total="total_items"
						:page="page"
						:page_limit="11"
						v-on:change="updatePage($event)"
						></cc-pagination>
					</div>
       			 </div>
      		</div>
			<new-request-modal
				id="modal"
				:products="selected_products()"
				:quotation="quotation"
				@finish="success()"
				:payment_types="payment_types"
				v-if="show_modal"
				@close="show_modal = false"
    		/>
			<add-product-modal
				id="modal"
				@add_product="add_temp_product"
				v-if="show_add_product_modal"
				@close="show_add_product_modal = false"
                :to_request="true"
    		/>
			<cc-product-brands
				id="modal"
				@add_product="add_temp_product"
				v-if="brands"
				:brands="brands"
				:product="current_product"
				@close="brands = null"
    		/>
            <cc-product-wrap
                v-if="open_edit_wrap"
                :product="{ ...current_product, embalagem: current_product.pro_embalagem, idProduto: current_product.pro_id }"
                @close="update_wrap"
            />
    	</div>
  	</div>
</template>
<script>
import Pagination from "@/components/cliente/base-components/Pagination";
import * as CONSTANTS from "@/constants/constants";
import QuotationService from "@/services/QuotationService";
import V2RequestsService from "@/services/v2/requests.service";
import OrderService from "@/services/OrderService";
import ProductService from "@/services/ProductService";
import ProviderService from "@/services/ProviderService";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import NewRequestModal from "@/modals/new-request/new-request.modal";
import AddProductModal from "@/modals/add-product/add-product.modal";
import ProductBrandsModal from "@/modals/product-brands/product-brands.modal";
import { typingRemoteRequestControlMixin } from "@/mixins/typing-remote-request-controls.mixin";
import StorageService from '@/services/local-storage.service';
import ProductWrapModal from '@/modals/product-wrap/product-wrap.modal'

export default {
	mixins: [ typingRemoteRequestControlMixin ],
	components: {
        ccPagination: Pagination,
        'cc-product-wrap': ProductWrapModal,
		'cc-product-brands' : ProductBrandsModal,
		'new-request-modal': NewRequestModal,
		'add-product-modal': AddProductModal
	},
	data() {
		return {
			is_loading: false,
			CONSTANTS: CONSTANTS,
			items_by_page_value: 50,
			page: 1,
			total_items: 0,
			product_name: "",
			only_typed_filter: false,
			total_itens_no_filter: null,
			provider: {},
			products: [],
			force_reload_products: false,
			payment_types: [],
			temp_map: new Map(),
			quotation: {
				loja: null,
				categoria: null,
				type: null
			},
            show_modal: false,
            open_edit_wrap: false,
			show_add_product_modal: false,
			stores: [],
			categories: [],
			providers: [],
			service : new QuotationService(),
			v2_service: new V2RequestsService(),
			product_service : new ProductService(),
			orderService : new OrderService(),
			types: [
				{ name : "Cotacao", id: "DEFAULT" },
				{ name : "Enviar para Central ", id: "HEADQUARTER" },
				{ name : "Compra Direta", id: "DIRECT" }
			],
			buscaProduto: '',
            categoria: '',
            comprado: null,
            comprados: false,
            naoComprados: false,
            seletecdValor: "",
            arrayTableGet:null,
            arrayCategoria: null,
            arraySubcategoria:null,
            arrayEmbalagem: null,
            categoriaModal: '',
            proEan: '',
            embalagemModal:'',
            codigoModal: '',
            tituloModal: '',
            produtoModal: '',
            genericoModal: 0,
            unidadeModal: '',
            isGenerico: false,
            subcategoriaModal: '',
            errorProdutoModal: false,
            errorCodigoModal: false,
            errorUnidadeModal: false,
            errorEmbalagemModal: false,
            errorQtdEmbalagemModal: false,
            errorCategoriaModal: false,
            errorSubcategoriaModal: false,
            isAdicional: false,
            prodId: '',
            serverMsg: '',
            qtdEmbalagemModal: '',
            botaoCadastrar: false,
            items_by_page_value: 50,
            total_items: 1000,
            arrayMarcas: [],
            nomeProdutoModalMarca: '',
            buscaMarca: '',
            marcaPreferencial: null,
			brands: null,
			current_product: null,
			breadcrumbs: [
                { name: "Nova Compra", route: 'cliente-new-request' }
            ]
		}
	},
	watch: {
		provider: function() {
			this.loadProviderProducts()
		},
		'quotation.categoria': function() {
            if(this.only_typed_filter) {
                let result = []
                this.load_temp_products().then(data => {
                    this.temp_map.forEach((k, v) => { result.push(k.pro_ean) })
                    if(result.length == 0)result = null;
                    this.load_products(result)
                })
            } else {
                this.load_products()
            }
		},
		only_typed_filter: function(checked) {
			this.load_typed_products(checked)
		}
	},
	methods: {
        update_wrap(wrap) {
            this.open_edit_wrap = false;
            this.$set(this.current_product,'pro_embalagem', wrap.wrap)
        },
		clear() {
			this.$swal.fire({
					title: 'Confirma remoção dos produtos selecionados?',
					text: "Os produtos digitados por serão removidos.",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Sim, continuar!'
				}).then((result) => {
					if(result.value) {
						this.clear_temp_products(() => {
							this.products.forEach(p => p.new_amount = 0);
							this.$swal.fire(
								'Seleção Removida!',
								'Digitações removidas com sucesso!',
								'success'
							).then((result) => {

							});
						})
					}
				});
		},
		check_type(val) {
			if(val && val.id)this.quotation.type = val;
			if(this.quotation.type && this.quotation.type.id) {
				if(this.is_direct_sale()) {
					this.products = [];
					this.force_reload_products = true;
					this.temp_map = new Map();
				} else {
					this.load_products()
				}
			}
		},
		is_direct_sale() {
			return this.quotation.type && this.quotation.type.id == "DIRECT";
		},
		count() {
			let length = 0;
			this.temp_map.forEach(m => {
				if(m.quantidade) {
					length++;
				}
			})
			return length;
		},
		build_products() {
			if(this.is_direct_sale()) {
				return this.selected_products().map(p => {
					return Object.assign({}, { quantidade: p.quantidade, pro_id: p.pro_id, preco_unitario: p.preco_unitario, preco_embalagem: p.preco_embalagem })
				})
			} else {
				return this.selected_products().map(p => Object.assign({}, { quantidade: p.quantidade, idProduto: p.pro_id, estoque: 0, observacao: '' }))
			}
		},
		open_modal() {
			if(this.count() == 0) {
				this.$swal.fire(
                    "Ops!",
                    "Escolha ao menos 1 item para continuar.",
                    "error"
                  );
			} else if(this.quotation.type.id == "HEADQUARTER") {
				this.$swal.fire({
					title: 'Confirma envio da requisição para central?',
					text: "Sua requisição será enviada para central.",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Sim, continuar!'
				}).then((result) => {
					if(result.value) {

						this.is_loading = true;

						var payload = {
							produtos: this.build_products(),
							idLoja: this.quotation.store ? this.quotation.store.loj_id : null,
						}
						this.service.inserircotacaocentral(payload)
						.then(response => response.data)
						.then(data => {
							this.success();
						})
						.finally(() => this.is_loading = false)
						.catch(error => {
							this.loading = false;
							ErrorHandlerService.handle(error, this.$store);
						});
					}
				});
			} else if(this.quotation.type.id == "DIRECT") {
				this.$swal.fire({
					title: 'Confirma envio de pedido de compra?',
					text: "Seu pedido de compra será enviado para o fornecedor.",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Sim, continuar!'
				}).then((result) => {
					if(result.value) {

						this.is_loading = true;

						var payload = {
							itens: this.build_products(),
							loj_id: this.quotation.store ? this.quotation.store.loj_id : null,
							for_id: this.provider ? this.provider.for_id : null
						}
						this.orderService.saveDirectSaleOrder(payload)
						.then(response => response.data)
						.then(data => {
							this.success();
						})
						.finally(() => this.is_loading = false)
						.catch(error => {
							this.loading = false;
							ErrorHandlerService.handle(error, this.$store);
						});
					}
				});
			} else if(this.quotation.type.id == "INTERNAL") {
				this.$swal.fire({
					title: 'Confirma registro de compra interna?',
					text: "Sua compra será registrada internamente e NÃO será enviada aos fornecedores.",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Sim, continuar!'
				}).then((result) => {
					if(result.value) {
						this.is_loading = true;

						var payload = {
							produtos: this.build_products(),
							cot_idLoja: this.quotation.store ? this.quotation.store.loj_id : null,
							for_id: this.provider ? this.provider.for_id : null,
							CTYPE: this.quotation.type.id,
							entrega: StorageService.tpc()
						}
						this.v2_service.create(payload)
						.then(response => response.data)
						.then(data => {
							this.success();
						})
						.finally(() => this.is_loading = false)
						.catch(error => {
							this.$swal.fire(
								'Ops! um Erro ocorreu!',
								error.response,
								'error'
							)
							this.loading = false;
							ErrorHandlerService.handle(error, this.$store);
						});
					}
				});
			} else {
				this.show_modal = !this.show_modal;
			}
		},
		selected_products() {
			let products = [];
			this.temp_map.forEach(p => { if(p.quantidade)products.push(p) })
			return products;
		},
		success() {
			if(this.is_direct_sale()) {
				this.clear_temp_products(() => {
					this.products.forEach(p => p.new_amount = 0);
					this.$swal.fire(
						'Pedido Realizado!',
						'Seu pedido foi realizado com sucesso.',
						'success'
					).then((result) => {
						this.$router.push({ name: 'monitors-page', params: { tab: 'pedidos' }, query: { po: 1 } })
					});
				})
			} else {
				let message = 'Sua cotação foi enviada com sucesso.';
				if(this.quotation.type.id == 2) {
					message = 'Sua requisição foi enviada com sucesso.';
				}
				this.clear_temp_products(() => {
					this.products.forEach(p => p.new_amount = 0);
					this.$swal.fire(
						'Publicado!',
						message,
						'success'
					).then((result) => {
						this.$router.push({ name: 'monitors-page', params: { tab: 'cotacoes' } })
					});
				})
			}
		},
		itens_amount() {
			return this.products.filter(p => p.new_amount > 0).length;
		},
		totalDirectSaleAmount() {
			let ids = []
			let result = 0;

			for (let [key, value] of this.temp_map.entries()) {
				if(value.preco_embalagem && value.quantidade)result += parseFloat(value.preco_embalagem*value.quantidade);
			}
			return result;
		},
		clear_temp_products(callback){
			this.orderService.clearRequestTempProducts({ type: this.req_type })
			.then(() => {
				if(callback)callback();
				this.temp_map = new Map();
			})
		},
		save_temp(prod) {

			let products = []

			if(this.is_direct_sale()) {
				this.products.forEach(p => {
							this.temp_map.set(p.pro_ean, { 	pro_ean: p.pro_ean,
												quantidade: parseInt(p.new_amount),
												quantidade_emb: parseInt(p.pro_embalagemQtd),
												pro_id: p.pro_id,
												preco_unitario: parseFloat(p.preco_unitario),
												tipo: this.req_type,
												preco_embalagem: parseFloat(p.pro_embalagemQtd*p.preco_unitario)
											})
				})

				this.temp_map.forEach(p => { if(p.quantidade)products.push(p) })
				let temps = { produtos: products };

				this.orderService.createRequestTempProducts(temps)
				.then(response => response.data)
				.then(data => {
					data.produtos.forEach(tmp => {
						this.products.forEach(p => {
							if(tmp.pro_id == p.pro_id) {
								this.$set(p, 'new_amount', parseInt(tmp.quantidade))
							}
						})
					})
					this.quotation.produtos = temps.produtos;
					this.$forceUpdate();
				})
			} else {

				this.products.forEach(p => {
					this.temp_map.set(p.pro_ean, { tipo: this.req_type,
												   pro_ean: p.pro_ean,
												   pro_id: p.pro_id,
												   quantidade: p.new_amount })
				})

				this.temp_map.forEach(p => { if(p.quantidade)products.push(p) })
				let temps = { produtos: products, type: this.req_type };

				this.orderService.createRequestTempProducts(temps)
				.then(response => response.data)
				.then(data => {
					data.produtos.forEach(tmp => {
						this.products.forEach(p => {
							if(tmp.pro_ean == p.pro_ean) {
								p.new_amount = tmp.quantidade
							}
						})
					})
					this.quotation.produtos = temps.produtos;
					prod.changed = false
					this.$forceUpdate();
				}).catch(() => {
                    prod.new_amount = null
                    this.temp_map.delete(prod.pro_ean)
                    const notification = {
                        type: 'error',
                        message: 'Não foi possível salvar a quantidade!'
                    }
                    this.$store.dispatch('notification/add', notification);
                    this.$forceUpdate();
                })
			}

		},
		add_temp_product(prod) {
			this.products.filter(p => p.pro_ean == prod.pro_ean).forEach(p => {
				this.$set(p, 'new_amount', prod.new_amount)
			})
			this.temp_map.set(prod.pro_ean, { pro_id: prod.pro_id, pro_ean: prod.pro_ean, quantidade: prod.new_amount, tipo: this.req_type });
			this.save_temp(prod);
		},
		is_focused(idx, product){
			let inputs = this.$refs["product-amount"];
			this.products.forEach(p => p.focused = false)
			if(inputs && $(inputs[idx]).is(":focus")) {
				this.$set(product, "focused", true);
				this.$forceUpdate();
			}
		},
		process_event(e, prod) {
			let key = getKey(e)
			let idx = parseInt(e.target.dataset.idx);
			let inputs = this.$refs["product-amount"];

			inputs.sort((a, b) => {
				let pos1 = parseInt(a.dataset.idx);
				let pos2 = parseInt(b.dataset.idx);
				if (pos1 > pos2) return 1;
				if (pos2 > pos1) return -1;
				return 0;
			})

			let next_idx = idx+1 == inputs.length ? 0 : idx+1;
			let prev_idx = idx == 0 ? inputs.length-1 : idx-1;
			function getKey(e) {

				if (e.key) return e.key;
				let keyFromCode = String.fromCharCode(e.keyCode);

				if (keyFromCode) return keyFromCode;
				// add here the tricky keys that you use in your app
				if (e.keyCode === 13) return "Enter";
				if (e.keyCode === 16) return "Shift";
				if (e.keyCode === 8) return "Tab";
				// etc
			}
			if(key == "ArrowUp") {
				e.preventDefault()
				if(prod.changed)this.save_temp(prod);
				inputs[prev_idx].focus()
                inputs[prev_idx].select()
			} else if(key == "ArrowDown") {
				e.preventDefault()
				if(prod.changed)this.save_temp(prod)
				inputs[next_idx].focus()
                inputs[next_idx].select()
			} else if(key == "Enter" || key == "Tab") {
				e.preventDefault()
				if(prod.changed)this.save_temp(prod);
				inputs[idx].focus()
                inputs[idx].select()
			} else {
				prod.changed = true
			}
		},
		load_typed_products(only_typed = false) {
			let result = [];
			this.only_typed_filter = only_typed;

			if(this.is_direct_sale()) {
				if(this.only_typed_filter) {
					this.load_temp_products().then(data => {
						this.temp_map.forEach((k) => result.push(k.pro_id))
						if(result.length == 0)result = null;
						this.load_products(result)
					})
				} else {
					this.load_products();
				}
			} else {
				if(this.only_typed_filter) {
					this.load_temp_products().then(data => {
						this.temp_map.forEach((k, v) => { result.push(k.pro_ean) })
						if(result.length == 0)result = null;
						this.load_products(result)
					})
				} else {
					this.products = []
					this.load_products();
				}
			}
		},
		updatePage(new_page) {
			this.page = new_page;
            let result = [];
			if(this.is_direct_sale()) {
				this.loadProviderProducts();
			} else {
                if(this.only_typed_filter) {
					this.load_temp_products().then(data => {
						this.temp_map.forEach((k, v) => { result.push(k.pro_ean) })
						if(result.length == 0)result = null;
						this.load_products(result)
					})
				} else {
                    this.load_products();
                }
			}
		},
		load_stores() {
			this.service.getStoresClient()
			.then(response => response.data)
			.then(data => {
				this.stores = data.requisitantes;
				this.stores.forEach(s => s.loj_nomeFantasia = s.loj_nomeFantasia.toUpperCase())
			})
		},
		load_categories() {
			this.service.getCategorias()
			.then(response => response.data)
			.then(data => {
				this.categories = data.categorias;
			})
		},
		load_temp_products() {
			return this.orderService.getRequestTempProducts({ type: this.req_type })
			.then(response => response.data)
			.then(data => {
				this.temp_map = new Map();
				data.produtos.forEach(tmp => {
					this.temp_map.set(tmp.pro_ean, tmp);
					if(this.products) {
						this.products.forEach(p => {
							if(tmp.pro_ean == p.pro_ean) {
								tmp.preco_embalagem = this.is_direct_sale() ? p.pro_embalagemQtd*tmp.preco_unitario : tmp.preco_embalagem;
								this.$set(p, 'new_amount', tmp.quantidade)
								this.$forceUpdate();
							}
						})
					}
					if(this.is_direct_sale())this.temp_map.set(tmp.pro_id, tmp);
				})
			})
		},
		loadDirectSaleProviders(){
			this.orderService.getDirectSaleProviders()
			.then(response => response.data)
			.then(data => {
				this.providers = data.fornecedores;
			})
		},
		loadProviderProducts(pro_ids) {
			this.is_loading = true;

			let payload = {
				ids: pro_ids,
				page: this.page,
				product_name: this.product_name
			}
			this.orderService.getProviderProductsForCurrentClient(this.provider ? this.provider.for_id : null, payload)
			.then(response => response.data)
			.then(data => {
				this.total_items = data.total;
				this.products = data.data.filter(p => p.product).map(p => {
					p.product.preco_unitario = p.Preco;
					return p.product;
				});
				this.load_temp_products();
				this.is_loading = false;
			}).catch((err) => {
				this.products = [];
				this.is_loading = false;
				ErrorHandlerService.handle(err, this.$store);
			});
		},
		load_products(pro_eans = null) {

			if(!(pro_eans instanceof Array)) {
				pro_eans = null
			}

			if(!pro_eans)this.only_typed_filter = false;

			if(this.is_direct_sale()) {
				this.loadProviderProducts(pro_eans);
			} else {

				const callback = (data) => {
					if(data) {
						this.products = data.produtos.data;
						this.total_items = data.produtos.total;
						if(pro_eans == null && this.product_name == "" && !this.quotation.categoria) {
							this.total_itens_no_filter = data.produtos.total;
						}
					}
					this.products.forEach(p => p.new_amount = null);
					this.products.forEach(p => p.changed = false);
					this.load_temp_products();
					this.is_loading = false;
					this.$forceUpdate();
				}

				this.is_loading = true
				this.$forceUpdate();

				if(this.force_reload_products) {
					this.products = [];
					this.force_reload_products = false;
				}

				this.cancelSearch(() => { this.is_loading = true });

				this.service.getProdutosClientePorPagina({
					categoria: this.quotation.categoria ? this.quotation.categoria.cat_id : null,
					nomeproduto: this.product_name,
					page: this.page,
					items_by_page_value: this.items_by_page_value,
					deleted: 0,
					pro_eans: pro_eans
				}, this.cancelSource.token)
				.then(response => response.data)
				.then(callback).catch(error => {
					ErrorHandlerService.handle(error, this.$store);
				});
			}
		},
		showMarcas(product){
			this.product_service.get_product_brands(product.pro_id)
			.then((data)=>{
				this.brands = data.data.produtos
				this.current_product = product
			})
      	}
	},
	computed: {
		req_type() {
			if(this.quotation.type == null)return null;
			return this.quotation.type.id == 'DIRECT' ? 'DIRECT' : 'DEFAULT'
		}
	},
	created() {
		this.loadDirectSaleProviders();
		this.load_stores();
		this.load_categories();
		this.load_products();
		this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
	},

};
</script>
<style lang="scss" scoped>
@import "@/sass/commons/_variables";
.body{
	width: calc(97%) !important;
}

</style>
