<style lang="scss" scoped>
	@import "./add-product.modal";
</style>
<template>
	<cc-modal :modal="modal" @close="close">
		<div slot="body" class="container-fluid pt-0">
			<div class="row contents">
				<div class="col-8">
					<form @submit="load_products()">
						<div class="row">
							<div class="col">
								<label for="" class="label-control"></label>
								<cc-search :placeholder="'Pesquisar produto'" class="mt-1" v-model="product_name" @keyup="load_products()" />
							</div>
							<div class="col">
								<label for="" class="label-control">Categoria</label>
								<cc-select-v2 :tracker="'cat_id'" :text="'cat_descricao'" :options="categories" v-model="cat"></cc-select-v2>
							</div>
                            <div class="col action-buttons">
								<label for="" class="label-control"></label>
								<cc-button :classes="'theme-2 fixed mt-1'" :icon="'fa fa-plus'" @click="new_product = {}" :content="'Novo Produto'"  />
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="row contents">
				<div class="col-lg-12 shadow p-4">
					<div class="cc-loader" :class="{ hidden : !is_loading }">
						<img src="/images/loader.svg"/>
						<span>Carregando</span>
					</div>
					<table class="table winners" :class="{ hidden : is_loading }">
						<thead class="gray">
							<tr>
								<th class="pl-4">Produto</th>
								<th>Embalagem</th>
								<th>Codigo</th>
							</tr>
						</thead>
						<tbody>

							<tr v-for="(product, idx) in products" :key="'prod-' + product.pro_id" :class="{ active : product.focused == true}">
								<td class="product" :class="{ added : product.added }">
									<div v-if="!add_only">
										<input
											min="0"
											type="number"
											:data-idx="idx"
											ref="product-amount"
											class="product-amount"
											:class="{ active : product.new_amount && product.new_amount > 0 && product.is_saved == true}"
											v-model.number="product.new_amount"
											@focus="is_focused(idx, product)"
											@keydown="process_event($event, product)"
                                            @blur="add_product({ ...product, checked: product.new_amount > 0 }, product)"
											:key="'qtd' + product.prod_id" />
											{{product.pro_descricao}}
									</div>
									<cc-checkbox class="mt-1" v-if="add_only"
                                        :checked="product.saved"
                                        :disabled="product.saved"
                                        :label="product.pro_descricao.toUpperCase()" @change="(evt) => {
                                            if(!adding){
                                                product.checked = evt.target.checked
                                                add_product(product)
                                            }
                                        }"/>
								</td>
								<td>{{ product.pro_generico ? product.pro_unidade_compra : product.pro_embalagem }}</td>
								<td>{{ product.pro_ean }}</td>
							</tr>

						</tbody>
					</table>
				</div>
			</div>

			<div class="footer" :key="'footer'">
				<cc-pagination
				:key="'pag'"
				classes="orange"
				:items_by_page_value="items_by_page_value"
				:total="total_items"
				:page="page"
				:page_limit="9"
				v-on:change="update_page($event)"
				></cc-pagination>
			</div>

            <new-product-modal
            v-if="new_product"
            :product="new_product"
            :to_request="to_request"
            :categories="categories"
            @emit_product="emit_product"
            @reload_products="load_products"
            @close="new_product = null" />
		</div>
	</cc-modal>
</template>

<script>

import QuotationService from "@/services/QuotationService";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import Pagination from "@/components/cliente/base-components/Pagination";
import NewProductModal from "@/modals/new-product/new-product.modal";

export default {
    props: ["quotation", "payment_types", "add_only", "to_request" ],
	components: {
        ccPagination : Pagination,
        'new-product-modal': NewProductModal
   },
  	data() {
		return {
			products: [],
			cancelSource: null,
			page: 1,
			loading: false,
			total_items : 0,
			items_by_page_value: 10,
			is_loading: true,
            adding: false,
			categories: [],
			product_name: "",
            cat: "",
            new_product: null,
			service: new QuotationService(),
			modal: {
				title: "adicionar produto",
				subtitle: 'Escolha um item da base padrao do Club ou inclua um novo produto',
				icon_title: 'fas fa-cart-plus',
				cancel_text: 'Voltar',
				style: {
					width: "90%",
                    height: "700px"
				}
			},
		};
	},
	watch: {
		'cat': function() {
			this.load_products()
		}
	},
  	methods: {
		cancelSearch () {
			if (this.cancelSource) {
				this.cancelSource.cancel('Start new search, stop active search');

			}
		},
		process_event(e, product) {
			let key = getKey(e)

			let idx = parseInt(e.target.dataset.idx);

			let inputs = this.$refs["product-amount"];
			inputs.sort((a, b) => {
				let pos1 = parseInt(a.dataset.idx);
				let pos2 = parseInt(b.dataset.idx);
				if (pos1 > pos2) return 1;
				if (pos2 > pos1) return -1;
				return 0;
			})

			let next_idx = idx+1 == inputs.length ? 0 : idx+1;

			let prev_idx = idx == 0 ? inputs.length-1 : idx-1;

			function getKey(e) {

				if (e.key) return e.key;

				let keyFromCode = String.fromCharCode(e.keyCode);

				if (keyFromCode) return keyFromCode;

				// add here the tricky keys that you use in your app
				if (e.keyCode === 13) return "Enter";
				if (e.keyCode === 16) return "Shift";
				if (e.keyCode === 8) return "Tab";
				// etc
			}

			if(key == "ArrowUp") {
				e.preventDefault();
				if(product.new_amount > 0){
                    product.checked = true;
                    // this.add_product(product);
                };
				inputs.filter(i => prev_idx == i.dataset.idx).forEach(i => i.focus());
			} else if(key == "ArrowDown") {
				e.preventDefault();
				if(product.new_amount > 0){
                    product.checked = true;
                    // this.add_product(product);
                };
				inputs.filter(i => next_idx == i.dataset.idx).forEach(i => i.focus());
			} else if(key == "Enter") {
				e.preventDefault();
				if(product.new_amount > 0){
                    product.checked = true;
                    // this.add_product(product);
                };
				inputs.filter(i => idx == i.dataset.idx).forEach(i => i.focus());
			} else if(key == "Tab") {
				e.preventDefault();
				if(product.new_amount > 0){
                    product.checked = true;
                    // this.add_product(product);
                };
				inputs.filter(i => next_idx == i.dataset.idx).forEach(i => i.focus());
			}
		},
		close() {
			this.$emit("close");
		},
		is_focused(idx, product){
			let inputs = this.$refs["product-amount"];
			this.products.forEach(p => p.focused = false)
			if(inputs && $(inputs[idx]).is(":focus")) {
				this.$set(product, "focused", true);
				this.$forceUpdate();
			}
		},
		load_provider_default_categories() {
			this.service.load_provider_default_categories()
			.then(response => response.data)
			.then(data => {
				this.categories = data.categorias;
			})
		},
		load_products() {

			this.cancelSearch();

			this.cancelSource = axios.CancelToken.source();

			this.service.get_default_products({ page: this.page, produto: this.product_name, categoria: this.cat ? this.cat.cat_id : null }, (this.cancelSource ? this.cancelSource.token : null))
			.then(response => response.data)
			.then(data => {
				this.products = data.produtos.data;
				this.total_items = data.produtos.total;
				this.is_loading = false;
			})
			.catch(error => {
				ErrorHandlerService.handle(error, this.$store);
			});
		},
		build_product(prod) {
			return {
				cliente: this.service.getClientId(),
				pro_id: prod.pro_id,
				pro_descricao: prod.pro_descricao,
				pro_ean: prod.pro_ean,
				pro_observacao: prod.pro_observacao,
				pro_embalagem_text: prod.pro_embalagem,
				pro_unidade_compra: prod.pro_unidade_compra,
				pro_generico: prod.pro_generico,
				categoria_select: { cat_id: 2 }
			};
		},
		add_product(prod, product_ref = {}) {
			var payload = this.build_product(prod)
            this.adding = true;
            if(prod.checked && !prod.is_saved) {
                this.service.insert_client_product(payload)
                .then(response => response.data)
                .then(data => {
                    prod.pro_id = data.produto
                    prod.added = true
                    prod.is_saved = true
                    product_ref.pro_id = data.produto
                    product_ref.added = true
                    product_ref.is_saved = true
                    this.adding = false
                    this.$emit("add_product", prod)
                    this.$set(prod, 'checked', true)
                    this.$set(prod, 'saved', true)
                }).catch(error => {
                    this.loading = false;
                    this.adding = false
                    if(error.response.data.mensagem == "Código já existe") {
                        prod.pro_id = error.response.data.pro_id
                        prod.is_saved = true
                        product_ref.is_saved = true
                        this.$emit("add_product", prod);
                        this.$set(prod, 'saved', true)
                    } else {
                        this.$set(prod, 'saved', false)
                    }
                    ErrorHandlerService.handle(error, this.$store);
                });
            }

        },
        emit_product(prod) {
            this.$emit("add_product", prod)
        },
		update_page(page) {
			this.page = page;
			this.load_products();
		}
	},
	mounted() {
		this.load_provider_default_categories();
        this.is_loading = true;
		this.load_products();
	}
};
</script>
