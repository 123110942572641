import http from "./http";
import Service from "../Service";

export default class SellerGroupService extends Service {

	all(data) {
		return http.get("sellers/groups", {
			headers: this.getHeaders(),
			params: data
		});
	}

	load(id) {
		return http.get(`sellers/groups/${id}`, {
			headers: this.getHeaders()
		});
	}

	save(group) {
		return http.post(`sellers/groups/`, group, {
			headers: this.getHeaders()
		});
	}

	update(group) {
		return http.put(`sellers/groups/${group.id}`, group, {
			headers: this.getHeaders()
		});
	}

	remove(id) {
		return http.delete(`sellers/groups/${id}`, {
			headers: this.getHeaders()
		});
	}

	add_to_group(id, data) {
		return http.post(`sellers/groups/add/${id}`, data, {
			headers: this.getHeaders()
		});
	}

	remove_from_group(id, seller_id) {
		return http.delete(`sellers/groups/remove/${id}?seller_id=${seller_id}`, {
			headers: this.getHeaders()
		});
	}

}
