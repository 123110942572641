export const typingRemoteRequestControlMixin = {
    data() {
        return {
            cancelSource: null
        }
    },
    methods: {
        cancelSearch (after_cancel) {
            if (this.cancelSource) {
                this.cancelSource.cancel('Start new search, stop active search');
                if(after_cancel) {
                    after_cancel()
                }
			}
            this.cancelSource = axios.CancelToken.source();
        }
    }
}