<style lang="scss" scoped>
@import '@/sass/commons/_variables';

/deep/ {

	.sub-title {
		padding: 0;
		text-transform: uppercase;
		color: $orange;
		font-weight: 600;
	}

	.multiselect__single {
		color: black !important;
	}

	.multiselect__option.multiselect__option--highlight {
		background-color: gray !important;
	}

	.modal-body {
		border-radius: 5px;
		padding: 2rem;
		overflow: auto !important;
	}
}
</style>
<template>
	<cc-modal :modal="modal" @close="close">
        <div slot="header-actions">
            <div class="row">
                <div class="col actions">
                    <cc-button :content="'Editar Fornecedores'" :icon="'fa fa-users'" @click="select_sellers" v-if="!show_sellers" :classes="'fill success'"/>
                    <cc-button :content="'Voltar'" :icon="'fas fa-chevron-left'" @click="show_sellers = false" :classes="'fill default'" v-else />
                </div>
            </div>
        </div>
		<div slot="body" class="row">
			<cc-loader-full v-show="loading" />
            <template v-if="show_sellers">
                <div class="col-12 mb-3">
                    <cc-search :placeholder="'Vendedor ou Fornecedor'" :theme="'theme-green'" class="mt-1" v-model="filters.name" @input="filter" />
                </div>
                <div class="col">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="pl-3">
                                    <cc-checkbox :checked="filters.check_all"
                                        @change="(evt) => toggle_check(evt.target.checked)" />
                                </th>
                                <th>Fornecedor</th>
                                <th>Vendedor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="seller in sellers" v-bind:key="seller.usu_id" v-show="seller.visible">
                                <td class="pl-3">
                                    <cc-checkbox class="mt-1" v-model="seller.checked" :checked="seller.checked" />
                                </td>
                                <td>{{ seller.for_nome }}</td>
                                <td>{{ seller.usu_nome }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
            <template v-else>
                <div class="col-12">
                    <div>
                        <p class="col-sm-12 sub-title">INFORMAÇÕES DA COTAÇÃO</p>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group" id="datetime-wrp">
                        <label for="inputCodigo" class="col-sm-12 control-label">Data de expiração</label>
                        <datetime :min-datetime="min_date" ref="datepicker" v-model="ressuply.expiration_date" auto :minute-step=10 type="datetime" class="form-control"></datetime>
                        <span class="picker rounded-right" @click="open_datepicker">
                            <i class="fa fa-calendar"></i>
                        </span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="inputCodigo" class="col-sm-12 control-label">Tipo de entrega</label>
                        <multiselect v-model="ressuply.delivery_type" track-by="code" label="label" placeholder="" open-direction="below" selected-label="Selecionado" :options="deliveryTypes"  :searchable="true" :allow-empty="false" :show-pointer="true" :show-labels="false"/>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="inputCodigo" class="col-sm-12 control-label">Forma de Pagamento</label>
                        <multiselect v-model="ressuply.payment_type" track-by="code" open-direction="below" placeholder="" label="label" selected-label="Selecionado" :options="payment_types" :searchable="true" :allow-empty="false" :show-pointer="true" :show-labels="false"/>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group pl-2">
                        <label for="inputCodigo" class="col-sm-12 control-label"></label>
                        <cc-checkbox @change="(evt) => ressuply.is_public = evt.target.checked" :checked="ressuply.is_public" :label="'Cotação Publica?'" />
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="form-group">
                        <label for="inputCodigo" class="col-sm-12 control-label">Selecionar Grupos</label>
                        <cc-select-v2 :multiple="true" :tracker="'id'" :text="'nome'" :options="groups" v-model="ressuply.groups"></cc-select-v2>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="inputCodigo" class="col-sm-12 control-label">Observação</label>
                        <input placeholder="" type="text" class="form-control" v-model="ressuply.observation" />
                    </div>
                </div>
            </template>
		</div>
	</cc-modal>
</template>

<script>
import QuotationService from "@/services/QuotationService";
import ProductService from "@/services/v2/products.service";
import { Datetime } from 'vue-datetime';
import ErrorHandlerService from '@/services/ErrorHandlerService';
import StorageService from '@/services/local-storage.service';
import SellerGroupService from "@/services/v2/seller-group.service";
import { mapGetters } from 'vuex'

export default {
  props: ["products", "quotation"],
  components: { datetime: Datetime },
  data() {
    return {
		min_date: moment().toDate().toISOString(),
        groups: [],
        filters: {
            check_all: true
        },
		modal: {
			title: "enviar cotação",
			unpermitted_message: "Aceite o termo de consentimento para continuar.",
			permitted: true,
			submit: this.sendResuply,
			submit_text: 'Enviar',
			subtitle: 'Envie sua cotação e receba as melhores ofertas no seu prazo',
			style: {
				width: "70%"
			}
		},
        show_sellers: false,
        sellers: [],
        prod_svc: new ProductService(),
		service : new QuotationService(),
        group_svc: new SellerGroupService(),
		payment_types: [],
		deliveryTypes: [
			{
				code: 1,
				label: "CIF"
			},
			{
				code: 2,
				label: "CIF/FOB"
			}
		],
		loading: false,
		ressuply: {
            is_public: false,
			dateExpiration: moment()
			.add("2", "days")
			.format("DD/MM/YYYY")
		}
    };
  },
  methods: {
    toggle_check(val) {
        this.sellers.forEach(s => s.checked = val)
        this.filters.check_all = val
        this.$forceUpdate()
        return val
    },
    filter(evt) {
        console.log(this.filters)
        this.sellers.forEach(s => s.visible = false)
        let rx = new RegExp(this.filters.name, 'gi')
        this.sellers.filter(s => s.usu_nome.match(rx) || s.for_nome.match(rx) || !this.filter.name)
        .forEach(s => s.visible = true)
    },
	open_datepicker() {
		document.getElementsByClassName('vdatetime-input')[0].click()
	},
    close() {
      this.$emit("close");
	},
	load_payment_types() {
		this.service.list_payment_types()
		.then(response => response.data)
		.then(data => {
			this.payment_types = data.data.map(p => { return { label: p.ttp_nome, code: p.ttp_id } });
		})
	},
    select_sellers() {
        this.loading = true
        this.show_sellers = true
        this.prod_svc.sellers_by_products(this.products.map(p => p.pro_id)).then(response => response.data).then(data => {
            this.sellers = data.data
            this.loading = false
            this.sellers.forEach(s => s.checked = true)
            this.sellers.forEach(s => s.visible = true)
        }).catch(() => {
            this.loading = false
        })
    },
    getStores() {
      var quotationService = new QuotationService();
      quotationService
        .getStoresClient()
        .then(response => response.data)
        .then(data => {
          this.stores = data.requisitantes.map(store => {
            return {
              value: store.loj_id,
              name: store.loj_descricao
            };
          });
          this.storeSelect = this.stores[0];
        })
        .catch(error => {
          ErrorHandlerService.handle(error, this.$store);
        });
    },
    load_groups() {
        this.group_svc.all()
        .then(response => response.data)
        .then(data => this.groups = data)
        .catch(error => ErrorHandlerService.handle(error, this.$store))
	},
	build_products() {
		return this.products.map(p => Object.assign({}, { quantidade: p.quantidade, idProduto: p.pro_id, estoque: 0, observacao: '' }))
	},
    sendResuply() {
		this.loading = true;
		var quotationService = new QuotationService();

		var payload = {
			produtos: this.build_products(),
			idLoja: this.quotation.store.loj_id,
			dataExpiracao: moment(this.ressuply.expiration_date).format("DD/MM/YYYY HH:mm"),
			entrega: this.ressuply.delivery_type ? this.ressuply.delivery_type.code : null,
			pagamento: this.ressuply.payment_type ? this.ressuply.payment_type.label : null,
            observacao: this.ressuply.observation,
            is_public: this.ressuply.is_public,
            group_ids: this.ressuply.groups ? this.ressuply.groups.map(g => g.id) : null
		}

        if(this.sellers.length > 0) {
            payload.fornecedores = this.sellers.filter(s => s.checked).map(s => s.usu_id).join(",")
        }

		if(this.quotation.type == 2) {
			quotationService.inserircotacaocentral(payload)
			.then(response => response.data)
			.then(data => {
				this.close();
				this.$emit("finish");
			})
			.catch(error => {
				this.loading = false;
				ErrorHandlerService.handle(error, this.$store);
			});
		} else {
			quotationService.inserirCotacao(payload)
			.then(response => response.data)
			.then(data => {
				this.close();
				this.$emit("finish");
			})
			.catch(error => {
				this.loading = false;
				ErrorHandlerService.handle(error, this.$store);
			});
		}
    },
	load_tpc() {
		this.ressuply.delivery_type = this.deliveryTypes.filter(d => d.code == StorageService.tpc())[0];
	},
    load_settings() {
        let DEFAULT_PRIVATE_REQUESTS_SETTING = this.setting('DEFAULT_PRIVATE_REQUESTS')
        this.$set(this.ressuply, 'is_public', DEFAULT_PRIVATE_REQUESTS_SETTING && DEFAULT_PRIVATE_REQUESTS_SETTING.value == '1')
    }
  },
  computed: {
    ...mapGetters({ setting: 'user/setting' })
  },
  mounted() {
	this.getStores()
	this.load_payment_types()
    this.load_tpc()
    this.load_groups()
    this.load_settings()
  }
};
</script>
