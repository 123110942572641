import http from "./Http";
import Service from "./Service";

export default class QuotationService extends Service {

	getDirectSaleProviders() {
		return http.get("providers/directsale", {
			timeout: 1200000,
			headers: this.getHeaders()
		});
	}

  	getProviderProductsForCurrentClient(provider_id, data) {
		return http.get(`providers/products/${provider_id}`, {
			headers: this.getHeaders(),
			params: data
		});
  	}

	getRequestTempProducts(data) {
		return http.get("listarprodutosrequisicaotemporaria", {
			headers: this.getHeaders(),
			timeout: 600000,
			params: data
		});
	}

	createRequestTempProducts(data) {
		return http.post("inserirprodutosrequisicaotemporaria", data, {
			headers: this.getHeaders()
		});
	}

	clearRequestTempProducts(data) {
		return http.delete("limparprodutosrequisicaotemporaria", {
			headers: this.getHeaders(),
			params: data
		});
	}

	saveDirectSaleOrder(data) {
		return http.post("orders", data, {
			headers: this.getHeaders()
		});
	}

	getDirectSaleOrders(params) {
		return http.get("orders", {
			headers: this.getHeaders(),
			params: params
		});
	}

	findOrdersByQuotationId(quotationId, fornecedor = undefined, seller = undefined) {
		return http.get("listarpedidos", {
			params: { numerocotacao: quotationId, singleorder: true, fornecedor: fornecedor, vendedor: seller},
			headers: this.getHeaders()
		});
	}

	getDirectSaleOrder(id) {
		return http.get(`orders/${id}`, {
			headers: this.getHeaders()
		});
	}

    get_order_data(order){
		return http.get("getorderdata", {
			headers: this.getHeaders(),
			timeout: 600000,
			params: {
				id_pedido: order.id_pedido,
				numeroCotacao: order.id_cotacao,
				loja: order.id_loja,
				id_vendedor: order.usu_id
			}
		});
	}

	exportPdfOrder(order){
		return http.get("gerarpdfpedido", {
			headers: this.getHeaders(),
			timeout: 600000,
			responseType: 'arraybuffer',
			params: {
				id_pedido: order.id_pedido,
				numeroCotacao: order.id_cotacao,
				loja: order.id_loja,
				id_vendedor: order.usu_id
			}
		});
	}
}
